.Page {
    background-color: var(--color1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.Content {
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AvatarContent {
    margin-top: 4rem;
}

.Avatar {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    /* background-color: var(--color6); */
    background: linear-gradient(180deg, rgba(140,94,43,1) 0%, rgba(200,153,79,1) 35%, rgba(140,94,43,1) 60%, rgba(200,153,79,1) 85%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.AvatarImage {
    width: 95%;
    aspect-ratio: 1;
    border-radius: 100%;
}

.Title { 
    margin-top: 2rem;
    font-family: PPEiko;
}

.Icons {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    width: 100%;
}

.IconsRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    /* gap: 1.2rem; */
    width: 95%;
}

.Icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    min-width: 33.3%;
}

.IconAvatar { 
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    /* background-color: var(--color6); */
    background: linear-gradient(270deg, rgba(140,94,43,1) 0%, rgba(200,153,79,1) 35%, rgba(140,94,43,1) 60%, rgba(200,153,79,1) 85%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.IconTitle h5{
    font-weight:normal;
}

.IconImage{
    width: 75%;
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Advertise {
    margin-top: 3rem;
    border: 1px solid var(--color6);
    border-color: linear-gradient(270deg, rgba(140,94,43,1) 0%, rgba(200,153,79,1) 35%, rgba(140,94,43,1) 60%, rgba(200,153,79,1) 85%);
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Advertise h4 {
    font-weight: 500;
}

.AdvertiseContent{
    margin: .4rem .8rem;
    width: 100%;
    text-align: center;
}

.Logo {
    margin: 3rem 0;
    width: 55%;
}

.LogoImage {
    width: 100%;
}